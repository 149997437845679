export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Carolyn Perkins',
        email: 'carolyn.p@Genni.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin',
    },
]
