import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {getCookie, setCookie} from 'react-use-cookie';
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { usersData } from 'mock/data/usersData';

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()
    const { token, signedIn } = useSelector((state) => state.auth.session)
    const signIn = async (values) => {
        const res3 = await fetch(
            `https://portalapi.genni.com/api/v1/auth/login`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username:values.email, password: values.password})
        })
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'failed',
                    message: "Invalid email or password",
                }
            }
            throw res;
            //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
                return data
            }
        );
        return res3;
    }

    const adminSignIn = async (values) => {
        // console.log("admin sign in");
        const res3 = await fetch(
            `https://portalapi.genni.com/api/v1/auth/adminlogin`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username:values.email, password: values.password, cp_masquerade_id: parseInt(values.CPID)})
        })
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'failed',
                    message: "Invalid email or password or CPID",
                }
            }
            throw res;
            //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
                return data
            }
        );
        return res3;
    }

    // const signUp = async (values) => {
    //     try {
    //         const resp = await apiSignUp(values)
    //         if (resp.data) {
    //             const { token } = resp.data
    //             dispatch(onSignInSuccess(token))
    //             if (resp.data.user) {
    //                 dispatch(
    //                     setUser(
    //                         resp.data.user || {
    //                             avatar: '',
    //                             userName: 'Anonymous',
    //                             authority: ['USER'],
    //                             email: '',
    //                         }
    //                     )
    //                 )
    //             }
    //             const redirectUrl = query.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
    //             )
    //             return {
    //                 status: 'success',
    //                 message: '',
    //             }
    //         }
    //     } catch (errors) {
    //         return {
    //             status: 'failed',
    //             message: errors?.response?.data?.message || errors.toString(),
    //         }
    //     }
    // }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
    }

    const signOut = async () => {
        setCookie("userInfo",'');
        setCookie("hasSeenModal",false);
        setCookie("emailsCopied",0);
        navigate(appConfig.unAuthenticatedEntryPath)

    }

    return {
        authenticated: getCookie("userInfo"),
        signIn,
        signOut,
        adminSignIn
    }
}

export default useAuth
